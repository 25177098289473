<template>
    <BaseSection>
        <template #body>
            <VJoySelect class="feature-flags-select" @update:model-value="updateSelectedFeatureFlag">
                <option value="" disabled selected>Features...</option>
                <option
                    v-for="featureFlag in featureFlags"
                    :key="featureFlag.feature"
                    :disabled="!featureFlag.isCanBeModifiedForUser"
                    :value="featureFlag.feature"
                >
                    {{ featureFlag.feature }} - {{ featureFlag.isEnabledForUser ? 'ON' : 'OFF' }}
                </option>
            </VJoySelect>
        </template>
        <template #actions>
            <VJoyButton variant="main" :disabled="!isEnabledSwitchButton" :loading="isLoading" @click.prevent="switchState">Switch state</VJoyButton>
        </template>
    </BaseSection>
</template>
<script setup lang="ts">
    import BaseSection from './BaseSection.vue';

    import {ref, computed} from 'vue';
    import type {FeatureFlag} from '#imports';
    import {useAsyncData, useFeatureFlag} from '#imports';
    import {VJoyButton, VJoySelect} from '@maltjoy/core-vue';

    const selectedFF = ref<FeatureFlag>();
    const isLoading = ref(false);
    const isEnabledSwitchButton = computed(() => !!selectedFF.value);

    const {data: featureFlags} = await useAsyncData('featureflags', () => initFeatureFlags());

    function initFeatureFlags() {
        return useFeatureFlag().fetchAllFeatureFlags();
    }

    function updateSelectedFeatureFlag(featureId: string) {
        selectedFF.value = featureFlags.value?.find((ff) => ff.feature === featureId);
    }

    function switchState() {
        isLoading.value = true;

        useFeatureFlag()
            .switchStateFeatureFlag(selectedFF.value!.feature, !selectedFF.value!.isEnabledForUser)
            .then(() => {
                const featureFlag = featureFlags.value?.find((ff) => ff.feature === selectedFF.value!.feature);
                if (featureFlag) {
                    featureFlag.isEnabledForUser = !featureFlag.isEnabledForUser;
                }

                isLoading.value = false;
                selectedFF.value = undefined;
            });
    }
</script>
<style scoped lang="scss">
    .feature-flags {
        &-select {
            margin-bottom: var(--joy-core-spacing-4);
        }
    }
</style>
