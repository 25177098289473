<template>
    <BaseSection data-testid="malt-footer-nuxt-admin-tools-translation">
        <template #body>
            <div>
                <VJoyToggle
                    :model-value="isActiveDebugTool"
                    label="Debug mode"
                    data-testid="malt-footer-nuxt-admin-tools-translation-debug"
                    @update:model-value="handleUpdateModelValueChangeDebug"
                >
                    Debug Mode
                </VJoyToggle>
            </div>
            <div>
                <VJoyToggle
                    :model-value="isActiveLogsTool"
                    label="Show logs"
                    data-testid="malt-footer-nuxt-admin-tools-translation-logs"
                    @update:model-value="handleUpdateModelValueChangeLogs"
                >
                    Show logs
                </VJoyToggle>
            </div>
        </template>
        <template #actions>
            <VJoyButton
                variant="main"
                :loading="isLoading"
                data-testid="malt-footer-nuxt-admin-tools-translation-cta-apply"
                @click="handleClickOnApply"
            >
                Apply
            </VJoyButton>
        </template>
    </BaseSection>
</template>
<script setup lang="ts">
    import {useTranslationAdminTools} from '#imports';
    import {onMounted, ref} from 'vue';
    import BaseSection from './BaseSection.vue';
    import {VJoyToggle, VJoyButton} from '@maltjoy/core-vue';

    const {isActivatedDebugTool, isActivatedLogsTool, switchDebugTool, switchLogsTool} = useTranslationAdminTools();

    const isLoading = ref(false);
    const isActiveDebugTool = ref(false);
    const isActiveLogsTool = ref(false);

    onMounted(() => {
        isActiveDebugTool.value = isActivatedDebugTool();
        isActiveLogsTool.value = isActivatedLogsTool();
    });

    function handleUpdateModelValueChangeDebug(value: boolean) {
        switchDebugTool(value);
    }
    function handleUpdateModelValueChangeLogs(value: boolean) {
        switchLogsTool(value);
    }
    function handleClickOnApply() {
        isLoading.value = true;
        // apply changes
        window.location.reload();
    }
</script>
