<template>
    <div v-if="isVisibleFooterAdminTools" data-testid="malt-footer-nuxt-admin-tools">
        <ClientOnly>
            <VJoyDrawer id="footer-admin-tools-drawer" ref="footerAdminToolsDrawer">
                <template #drawer-header>Tools</template>
                <template #drawer-body>
                    <div v-if="$slots['cta']" class="footer-admin-tools__cta">
                        <!-- You can chain multiple CTAs -->
                        <slot name="cta" />
                    </div>

                    <VJoyCollapse>
                        <VJoyCollapseItem v-if="isEnableFeatureFlagTool" header="Feature Flags" open>
                            <FeatureFlags />
                        </VJoyCollapseItem>

                        <VJoyCollapseItem v-if="isEnableTranslationTool" header="Translations" open>
                            <Translations />
                        </VJoyCollapseItem>
                    </VJoyCollapse>
                    <slot />
                </template>
            </VJoyDrawer>

            <div class="tools">
                <VJoyDrawerTrigger :drawer="footerAdminToolsDrawer">
                    <VJoyButton variant="admin" size="xsmall" icon="wench">Tools</VJoyButton>
                </VJoyDrawerTrigger>
            </div>
        </ClientOnly>
    </div>
</template>

<script setup lang="ts">
    import {computed, ref} from 'vue';
    import {useAuth} from '#imports';
    import FeatureFlags from './app-tools/sections/FeatureFlags.vue';
    import Translations from './app-tools/sections/Translations.vue';
    import {VJoyButton, VJoyDrawer, VJoyDrawerTrigger, VJoyCollapse, VJoyCollapseItem} from '@maltjoy/core-vue';

    const {hasEnoughPrivilege} = useAuth();

    const isEnableFeatureFlagTool = computed(() => hasEnoughPrivilege(['ROLE_ADMIN']) || import.meta.dev);
    const isEnableTranslationTool = computed(() => hasEnoughPrivilege(['ROLE_TRANSLATOR']) || import.meta.dev);
    const isVisibleFooterAdminTools = computed(() => isEnableFeatureFlagTool.value || isEnableTranslationTool.value);

    const footerAdminToolsDrawer = ref<InstanceType<typeof VJoyDrawer> | null>(null);
</script>

<style scoped lang="scss">
    .tools,
    :deep(.tools) {
        position: fixed;
        bottom: var(--joy-core-spacing-4);
        right: var(--joy-core-spacing-4);
        z-index: var(--joy-core-z-index-fixed-content);
    }

    .footer-admin-tools__cta {
        border-bottom: 1px solid var(--joy-color-neutral-30);
        padding-bottom: var(--joy-core-spacing-5);
        margin-bottom: var(--joy-core-spacing-5);
        display: flex;
        gap: var(--joy-core-spacing-3);
    }
</style>
