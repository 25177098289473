<template>
    <div>
        <div class="footer-tool__body">
            <slot name="body" />
        </div>
        <div class="footer-tool__actions">
            <slot name="actions" />
        </div>
    </div>
</template>
<style lang="scss">
    .footer-tool {
        &__body,
        &__actions {
            padding: var(--joy-core-spacing-2);
        }

        &__actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    }
</style>
